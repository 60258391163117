
$clr1: #282828; // darker
$clr2: #383838; // lighter
$font1: #f454ff; // heavy pink
$font2: #e2e2e2; // somewhat white
$font3: #54fdf5; // turqoise
$codeHighlightBackground: #272822; // somewhat black

img {
  margin: auto;
}

.post-list h3 {
  margin-top: 0;
}

/* do not wrap code sections if they are < 600px & make them scrollable if they are that small */
div.highlight {
  overflow-x: scroll;
}
@media only screen and (min-width: 750px) {
  div.highlight {
    overflow-x: auto;
  }
}
pre.highlight {
  min-width: 600px;
}
